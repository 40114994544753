<template>
	<div :class="themeClass">
		<el-dialog v-dialogDrag :close-on-press-escape="false" title="设置支付密码" :visible.sync="setPaymentPasswordVisible" width="425px"
		 :before-close="handleClose" :close-on-click-modal="false">
			<div class="form-Box">
				<el-form :model="formData" :rules="rules" ref="formData" label-width="100px" v-loading='formLoading'>
					<el-form-item label="手机号">
						<!-- <div class="phone-box">{{getUserInfo.adminPhone}}</div> -->
						<el-input v-model="getUserInfo.adminPhone" disabled>
						</el-input>
					</el-form-item>
					<el-form-item label="新密码" prop="password">
						<el-input v-model.number="formData.password"  autocomplete='new-password' autocapitalize="off"   maxlength='6' type='password' placeholder="请输入6位数字密码">
							<!-- <i slot="suffix" :class="icon" @click="showPass"></i> -->
						</el-input>
					</el-form-item>
					<el-form-item label="确认密码" prop="rePassword">
						<el-input v-model.number="formData.rePassword" maxlength='6' type='password' placeholder="请再次确认密码">
							<!-- <i slot="suffix" :class="icon" @click="showPass"></i> -->
						</el-input>
					</el-form-item>
					<el-form-item label="验证码" prop="Perfectregister" class="YZM" style="position: relative;">
						<div v-show="innerVisibles" style="position: absolute;bottom: 0px;z-index: 999; padding: 3px; background-color: #fff; left: 0;">
						<i  class="el-icon-circle-close" style="position: absolute;right: 14px;top: 6px;color: rgb(255 255 255);font-size: 26px;z-index: 2;background: #525252;border-radius: 50%;" @click="closeYanzheng"></i>
							<zhifuyanzheng @messageData='messageData'></zhifuyanzheng>
						</div>
						<el-input v-model="formData.Perfectregister" placeholder="输入验证码">
							<template slot="append">
								<div @click="getCode" v-if="verCodeText" style="color: #0173fe;cursor: pointer;">获取验证码</div>
								<div v-if="!verCodeText" style="color: #0173fe">{{verCodeNub}}秒</div>
							</template>
						</el-input>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<div class="flex-row-center-center">
					<div class="background-color-theme button-change-pay-pass pointer flex-row-center-center" @click="submitInform()">确定</div>
				</div>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import zhifuyanzheng from '@/components/payment/zhifuyanzheng';
	export default {
		props: {
			setPaymentPasswordVisible: {
				type: Boolean,
				default: false
			},
		},
		data() {
			var validatePass2 = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请再次输入密码'));
				} else if (value !== this.formData.password) {
					callback(new Error('两次输入密码不一致!'));
				} else {
					callback();
				}
			};
			return {
				verCodeText: true, // 是否显示验证码字段
				verCodeNub: "", // 验证码读数
				verCodeTimer: null, // 验证码计时器变量
				formLoading: false, //表单加载
				formData: {},
				// 验证码:
				innerVisibles: false,
				msg: false,
				
				rules: {
					password: [{
						required: true,
						message: '请输新密码',
						trigger: 'blur'
					}, {
						type: 'number',
						message: '密码必须为6位数字'
					}],
					rePassword: [{
						required: true,
						message: '请再次输入密码',
						trigger: 'blur'
					}, {
						validator: validatePass2,
						trigger: 'blur'
					}, {
						type: 'number',
						message: '密码必须为6位数字'
					}],
					Perfectregister: [{
						required: true,
						message: '请输验证码',
						trigger: 'blur'
					}],
				},
				isSet:false,//是否修改
			};
		},
		computed: {
			...mapGetters(["getThemeName", 'getUserInfo']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		created() {},
		mounted() {},
		components: {
			zhifuyanzheng
		},
		methods: {
			//接收验证码插件传来的值
			messageData(a) {
				if (a) {
					var _this = this;
					setTimeout(function() {
						_this.innerVisibles = false;
						_this.msg = a
						_this.getCode();
						console.log('子传递值，已经变成true')
					}, 500);
				}
			},
			closeYanzheng() {
				this.innerVisibles = false;
			},
			//表单验证
			submitInform() {
				this.$refs.formData.validate((valid) => {
					if (valid) {
						this.Auditing();
					} else {
						return false;
					}
				});
			},
			// 获取验证码
			getCode() {
				if (this.msg == false) {
					this.innerVisibles = true;
					return;
				}
				let _this = this;
				const TIME_COUNT = 60;
				if (!_this.verCodeTimer) {
					// TODO 临时用接口
					_this.ApiRequestPost("api/mall/ebcustomer/baseinfo/reset-walletpwd-msg").then(
						(res) => {
							if(res.code == "HengHeCode1000"){
									// _this.tipsSuccess("验证码发送成功");
									_this.verCodeNub = TIME_COUNT;
									_this.verCodeText = false;
									_this.verCodeTimer = setInterval(() => {
										if (_this.verCodeNub > 0 && _this.verCodeNub <= TIME_COUNT) {
											_this.verCodeNub--;
										} else {
											_this.verCodeText = true;
											clearInterval(_this.verCodeTimer);
											_this.verCodeTimer = null;
											_this.msg = false
										}
									}, 1000);
								}
						},
						(rej) => {}
					);
				}
			},
			//确定修改
			Auditing() {
				this.ApiRequestPost('api/mall/ebcustomer/baseinfo/reset-walletpwd', {
					pwd: this.formData.password,
					pwdConfirm: this.formData.rePassword,
					msgCode: this.formData.Perfectregister
				}).then(
					res => {
						console.log(res);
						if (res.code == 'HengHeCode1000') {
							this.isSet = true;
							this.handleClose()
						}
					},
					error => {}
				);
			},
			// 弹窗关闭事件
			handleClose() {
				let obj = {
					show: !this.setPaymentPasswordVisible,
					isSet: this.isSet,
				}
				this.$emit('change', obj)
				this.isSet = false;
			},
		}
	};
</script>

<style scoped lang="scss">
	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.bg-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
		}

		color: #FFFFFF;
	}

	.button-change-pay-pass {
		border-radius: 2px;
		width: 120px;
		height: 40px;
	}

	.form-Box {
		width: 400px;
	}

	.form-Box::v-deep {
		.el-input__inner {
			height: 30px !important;
			width: 100% !important;
			border-right: 1px solid #F2F2F2 !important;
		}

	}

	.YZM::v-deep {
		.el-input__inner {
			height: 30px !important;
			width: 100% !important;
			border-right: 1px solid #F2F2F2 !important;
			border-right: 0 !important;
		}

		.el-form-item__content {
			margin-top: 5px;
		}
	}
	.phone-box {
		margin-top: 5px;
		padding: 0 15px;
		height: 30px;
		line-height: 30px;
		border: 1px solid #f2f2f2;
		border-radius: 4px;
	}
</style>
